.banner-image-header {
    background-image: url('https://img.freepik.com/premium-photo/concept-indian-cuisine-baked-chicken-wings-legs-honey-mustard-sauce-serving-dishes-restaurant-black-plate-indian-spices-wooden-table-background-image_127425-18.jpg?w=2000');
     background-repeat: no-repeat;
     background-size: cover;
     height: 550px;
}
.text-color{
    color: white !important;
}
.nav-font-size{
    font-size: 20px;
    padding: 10px;
}
.dropdown-toggle::after{
    color: white !important;
}
.brand-wrap img {
    height: 50px;
    width: 60px;
}

/* .hc-nav-trigger span{
    top: 53%;
   
} */
.hc-nav-trigger span, .hc-nav-trigger span::before, .hc-nav-trigger span::after{
    background-color: white !important;
}
.header-component{
    background-color:#d92662;
 
    height: 100px;
    z-index: 11;
    width: 100%;
}
.brand-wrap img{
    background-color: white;
}
.carousel-control-prev{
    display: none !important;
}
.carousel-control-next-icon{
    display: none !important;
}
.dropdown{
    display: block !important;
}
.modal-height-content{
    height: 200px;
}
.makeStyles-formControl-5{
    width:92% !important;
}

.jss5{
    width:92% !important;
}
.css-1c0iqou-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment{
    display: none;
}
.css-gs227a .MuiOutlinedInput-root .MuiAutocomplete-endAdornment{
    display: none;
}
.search-amenity{
    text-align: center;
    width: 93%;
    margin: auto;
    margin-top: 15px !important;
}