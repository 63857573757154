.swiper-pointer-events{
    margin-bottom: 25px;
}
.img-fluid{
    border-radius: 10px !important;
}
.font-size-large{
    font-size: 15px;
    font-weight: bold;
}

.related .swiper-button-next {
    top: 120px !important;
    right: -19px !important;
}
.related .swiper-button-prev {
    top: 120px !important;
    right: -19px !important;
}