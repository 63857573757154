#map_canvas,
#wrapper,
#page-content-wrapper {
    height: 100%;
    width: 100%;
}

#sidebar-wrapper {
    position: absolute !important;
}

.container-fluid,
.row,
.col-lg-12 {
    height: 100%;
    width: 100%;
}

/* hamburger menu pseudo element */
.box-shadow-menu {
    position: relative;
    padding-left: 1.25em;
}

.box-shadow-menu:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.25em;
    width: 1em;
    height: 0.15em;
    background: black;
    box-shadow:
        0 0.25em 0 0 black,
        0 0.5em 0 0 black;
}

/* hamburger menu pseudo element gradient */
.gradient-menu {
    padding-left: 1.25em;
    position: relative;
}

.gradient-menu:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.21em;
    bottom: 0.21em;
    width: 1em;
    background: linear-gradient(to bottom,
            black, black 20%,
            white 20%, white 40%,
            black 40%, black 60%,
            white 60%, white 80%,
            black 80%, black 100%);
}

.nav-tabs>li {
    float: none;
}

.nav-tabs {
    border-bottom: 0;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    margin: 0;
    border-radius: 0;
}

#wrapper {
    background-color: white;
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
  /* margin-top: 100px ; */
    /* position: fixed; */
    left: 250px;
    width: 0;
    height: 80%;
    margin-left: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 360px;
    margin: 0;
    padding: 0 5px;
    list-style: none;
}

.sidebar-nav li {
    /* text-indent: 3px; */
    /* line-height: 40px; */
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: white;
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: black;
    background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
    /* height: 65px; */
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
    color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
    color: #fff;
    background: none;
}
@media(max-width:992px){
    #sidebar-wrapper {
        margin-top: 30px !important;
    } 
}
@media(min-width:768px) {
    #wrapper {
        padding-left: 350px;
    }

    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 420px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 0;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}

#side_bar li {
    color: black;
}
#side_bar .fa-star{
    color: silver;
}

.sidebar-nav .input-group {
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
    background: #fff;
    border-radius: 8px !important;
    margin-top: 10px;
}

.sidebar-nav .input_search {
    border-radius: 8px 0 0 8px !important;
}

.sidebar-nav .border_search {
    border-radius: 0 8px 8px 0 !important;
}

.listing_item {
    padding: 10px;
}

.content_image_flex {
    display: flex;
    justify-content: space-between;
}

.content_image img {
    border-radius: 10%;
    width: 140px;
    height: 140px;
}

.rating_item {
    padding: 0 10px;
}

.review_flex {
    display: flex;
    align-items: center;
}

.review_avatar img {
    width: 100%;
    border-radius: 50%;
}

.item_name {
    font-weight: 700;
    font-size: 18px;
}

.facebook-login {
    width: 50%;
    margin-bottom: 20px;
    margin-top: 40px !important;
}

.google-login {
    width: 50%;
    margin-top: 15px !important;
}

/* .modal-contentlogin {
    height: 50vh;
} */
.cmnt{
    font-size: 13px;
}ul {
    list-style-type: none;
  }
  
  li {
    display: inline-block;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  .bg-danger-color{
    display: block;
    position: relative;
    font-size: 15px;
    background: white;
    padding: 5px;
    height: 75px;
    width: 70px;
    border-radius: 10px;
    cursor: pointer;
  }
  :checked + .bg-danger{
    border-color: #ddd;
    background-color: #d92662;
    border-radius: 12px;
    
    text-align: center;
    width: 70px;
    height: auto;
    padding: 5px;
    height: 75px;
  }
  /* :checked + .bg-danger p{
    color: white;
  } */
  .font-size-label {
    
    display: block;
    position: relative;
    font-size: 15px;
    background: white;
    padding: 5px;
    height: 75px;
    width: 70px;
    border-radius: 10px;
    cursor: pointer;
  }
  
.font-size-label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  

  
  /* :checked + .font-size-label  {
    border-color: #ddd;
    background-color: #d92662;
    border-radius: 12px;
    color: white;
    text-align: center;
    width: 70px;
    height: auto;
    padding: 5px;
    height: 75px;
  } */
  /* :checked + .font-size-label p{
    color: white;
  } */
  /* :checked + .font-size-label:before {
    content: "✓";
    background-color: black;
    transform: scale(1);
  } */
  
  /* :checked + .font-size-label i {
    transform: scale(0.9);

    z-index: -1;
  } */
  .font-size-label{
    font-size: 15px;
  }
  .amenities-row{
    width: 100%;
    /* margin-top: 67px; */
    text-align: center;
    display: flex;
    position: absolute;
    top: 13px;
    gap: 10px;
    flex-wrap: wrap;
  }
  .gmnoprint{
    display: none;
  }
  .bg-danger{
    color: white;
    background-color: #d92662 !important;
  }
  .bg-danger p{
    color: white;
  }